.container {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.container h1 {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 5px;
  color: var(--noir);
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow-y: hidden;
}

.container .actions {
  display: flex;
  margin-left: -15px;
}

.actions a,
.actions button,
.close_block a {
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 50%;
  background: transparent;
  color: var(--noir);
}

.close_block a {
  margin-right: -15px;
}

.actions button {
  cursor: default;
}

.actions button.host_profile {
  cursor: pointer;
}

.actions a:hover,
.close_block a:hover,
.actions button.host_profile:hover {
  background-color: var(--gray);
}

.actions svg {
  height: 10px;
  width: 10px;
}

.actions svg.encours {
  fill: #ccc;
}

.actions svg.enregistrer {
  fill: #ffaf0f;
}

.actions svg.masquer {
  fill: #c13515;
}

.actions svg.publier {
  fill: #008a05;
}

.actions svg.desactiver {
  display: block;
  height: 14px;
  width: 14px;
  fill: rgb(193, 53, 21);
}

.actions svg.book_now {
  display: block;
  height: 16px;
  width: 16px;
  fill: rgb(255, 175, 15);
}

.actions svg.not_book_now {
  display: block;
  height: 16px;
  width: 16px;
  fill: #717171;
}

.actions svg.calendar {
  display: block;
  height: 16px;
  width: 16px;
  fill: currentColor;
  stroke: currentColor;
}

.actions svg.preview {
  display: block;
  height: 16px;
  width: 16px;
  fill: currentcolor;
}
