/*----checkox_contener---*/
.checkox_contener {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
  padding: 24px 0;
  border-top: 1px solid #dcdfe4;
}
.text {
  display: flex;
  flex-direction: column;
}
.checkox_contener p {
  color: #717171;
  font-size: 13px;
}
