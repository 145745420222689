.container {
  max-width: 800px;
  margin-top: 24px;
}

.container_load_liste {
  margin-top: 20px;
}

.container h1 {
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding-bottom: 10px;
}

.main {
  padding: 0 100px 100px;
}

.head_title {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
}

.no_result {
  margin-top: 20px;
}

@media screen and (max-width: 950px) {
  .main {
    padding: 0 24px 100px;
  }
}
