.modal_content {
  display: flex;
  flex-direction: column;
  width: 700px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  overflow-y: auto;
  padding: 20px 24px;
}

.modal_content .m_body .profil_block_avis {
  display: flex;
  gap: 10px;
}

.profil_block_avis .image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  text-decoration: none;
  background: #f1f1f1;
  border-radius: 50%;
  overflow: hidden;
}

.profil_block_avis .image_block img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}

.profil_block_avis .image_block h2 {
  text-transform: uppercase;
  color: var(--noir);
  font-size: 18px;
}

.modal_content .m_body .profil_block_avis p {
  color: var(--noir9);
  font-size: 14px;
  margin: 0;
}

.modal_content .m_body h4 {
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.modal_content .m_body p {
  margin: 10px 0 30px;
  font-size: 15px;
}

.modal_content .m_body ul {
  padding: 10px 0;
  margin-left: 18px;
}

.modal_content .m_body ul li {
  padding: 5px 0;
}

/*----checkox_contener---*/
.checkox_contener {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding-bottom: 24px;
  margin-bottom: 30px;
  border-bottom: 1px solid #dcdfe4;
}
.text {
  display: flex;
  flex-direction: column;
}
.checkox_contener label {
  width: 100%;
  font-size: 16px;
  color: var(--noir4);
  cursor: pointer;
}
.checkox_contener p {
  color: #717171;
  font-size: 13px;
  margin: 0 !important;
}

.checkox_contener .switch {
  position: relative;
  display: inline-block;
  width: 47px;
  height: 30px;
}

.checkox_contener .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 26px;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch.disable .slider {
  background-color: #e9e9e9;
  cursor: no-drop;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch input:checked + .slider {
  background-color: var(--btn_noir1);
}

.switch input:focus + .slider {
  box-shadow: 0 0 0 3px #c4dbf9;
}

.switch input:checked + .slider:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .modal_content h3 {
    padding-bottom: 5px;
  }

  .m_head {
    min-height: 48px;
  }

  .commentaire_content .m_body::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .profil_block_avis .image_block {
    width: 40px;
    height: 40px;
  }
}
