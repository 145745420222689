/*-------links_page------*/
.links_page {
  display: flex;
  gap: 10px;
  padding-top: 24px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--border-color);
  white-space: nowrap;
  overflow-x: auto;
}

.links_page button {
  display: inline-flex;
  background-color: var(--gray);
  border: none;
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir6);
  transition: 0.3s;
}

.links_page button:hover {
  background-color: #ebebeb;
}

.links_page button:active {
  transform: scale(0.9);
}

.links_page button.active {
  color: #fff;
  background-color: var(--btn_noir1);
  cursor: default;
}

.links_page button.active:active {
  transform: none;
}

@media screen and (max-width: 743px) {
  .links_page {
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .links_page::-webkit-scrollbar {
    display: none;
  }
}
