/* annonce */
.indicat_block,
.not_eligible {
  width: 100%;
  height: 5px;
  border-radius: 5px;
  overflow: hidden;
}

.indicat_block {
  background-color: #008a05;
  position: relative;
}

.indicat_block .indicator {
  position: absolute;
  inset: 0;
  background-color: #ff451b;
}

.not_eligible {
  background-color: #f1f1f1;
}

/* reservation */
.delay_free {
  padding: 5px 15px;
  background-color: #008a05;
  width: fit-content;
}

.delay_free h2 {
  color: #fff !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}