.container {
  display: flex;
  flex-direction: column;
  height: calc(var(--view-height) - var(--navbar-height));
  overflow: hidden;
}

.no_result {
  margin-top: 60px;
  text-align: center;
  padding: 0 30px;
}

.no_result h2 {
  color: var(--noir);
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  line-height: 33px;
}

.no_result p,
.no_result a {
  color: var(--noir4);
}

.no_result a:hover {
  text-decoration: none;
}

.container_load_list {
  margin-top: 70px;
}

/*-----------table------------*/
.container_arry {
  width: 100%;
  height: 100%;
  padding: 0 30px 50px;
  overflow: auto;
}

.container_arry .customers {
  position: relative;
  border-collapse: collapse;
  width: 100%;
}

.customers thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.customers th,
.customers tr {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  background-color: #fff;
}

.customers th,
.customers td {
  text-align: left;
  flex: 1;
  min-width: 190px;
}

.customers th {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: var(--font_display_2_font-weight);
  padding: 5px 8px;
  color: var(--noir3);
}

.customers td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 8px;
  color: rgb(114, 114, 114);
  font-size: 15px;
  word-break: break-all;
}

.customers tr:nth-child(even) {
  background-color: #fdfdfd;
}

.customers tr:hover,
.customers tr:hover td.image,
.customers td.image:hover {
  background-color: #f1f1f1;
}

.customers th.image,
.customers td.image {
  position: sticky;
  left: -30px;
  top: 0;
  background-color: #fff;
  flex: 1;
  min-width: 200px !important;
}

.customers td.image {
  padding: 0 !important;
}

.customers td.image a {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 8px;
  text-decoration: none;
  color: var(--noir);
}

.customers td .card {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #f1f1f1;
  margin-right: 15px;
  overflow: hidden;
}

.customers td .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customers td .card h2 {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: var(--font_display_2_font-weight);
}

.customers td.image p {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.container_arry .statut {
  display: flex;
  align-items: center;
}

.valid_block {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.valid_block span {
  font-size: 14px;
  color: var(--noir);
}

@media screen and (max-width: 950px) {
  .container_arry {
    margin-top: 0;
    padding: 0 24px 50px;
  }

  .customers th.image,
  .customers td.image {
    left: -24px;
  }
}

@media screen and (max-width: 820px) {
  .customers th {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .customers .rows,
  .customers .statut {
    display: none;
  }

  .customers td.image a {
    align-items: center;
  }
  .customers tr:hover,
  .customers tr:hover td.image,
  .customers td.image:hover {
    background-color: white;
  }
}
