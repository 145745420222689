/*-------profil_commentaire-----*/
.container_comente {
  padding: 30px 0;
  width: 600px;
  max-width: 100%;
}

.profil_commentaire h3 {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 19px;
  padding-bottom: 30px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.profil_commentaire .comment_list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profil_block_img {
  display: flex;
  gap: 10px;
}

.profil_block_img .image_block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  text-decoration: none;
  background: #f1f1f1;
  border-radius: 50%;
  overflow: hidden;
}

.profil_block_img .image_block img {
  width: 100%;
  height: 100%;
  vertical-align: middle;
  object-fit: cover;
}

.profil_block_img .image_block h2 {
  text-transform: uppercase;
  color: #000;
}

.pro_date h4 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.pro_date p {
  color: var(--noir9);
  font-size: 14px;
  margin: 0;
}

.profil_commentaire .message {
  margin-top: 20px;
  margin-bottom: 5px;
}

.profil_commentaire .hotel_img {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.profil_commentaire .hotel_img_card {
  width: 70px;
  height: 47px;
  background: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
}

.profil_commentaire .hotel_img_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profil_commentaire .message h4 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.profil_commentaire .message h5 {
  color: var(--noir9);
  font-size: 14px;
}

.profil_commentaire .message p {
  margin: 25px 0 5px;
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow-y: hidden;
}

.view_all {
  border-bottom: 1px solid #ccc;
  padding: 30px 0;
}

.view_all button {
  border: 1px solid var(--noir);
  padding: 13px 20px;
  border-radius: 8px;
  background: transparent;
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  transition: 0.3s;
  cursor: pointer;
}

.view_all button:hover {
  background: var(--gray);
}

.view_all button:active {
  transform: scale(0.9);
}

/*------see my comentes----*/
.myComente {
  display: flex;
  justify-content: flex-end;
  padding: 30px 0;
}

.myComente button {
  text-decoration: underline;
  color: var(--noir);
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  border: none;
  outline: none;
  background: transparent;
  transition: 0.3s;
  cursor: pointer;
}

.myComente button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 768px) {
  .container_comente {
    padding: 30px 0;
  }

  .profil_commentaire p {
    margin-right: 0;
  }
}

@media screen and (max-width: 700px) {
  .view_all button,
  .myComente button {
    width: 100%;
  }
}

@media screen and (max-width: 540px) {
  .image_block {
    width: 40px;
    height: 40px;
  }
}

/*---modal_commentaire_profil-----*/

.modal_content {
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  padding: 10px 24px 50px;
}

.modal_content .m_body .profil_block {
  display: flex;
  gap: 10px;
}

.modal_content .m_body h4 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.modal_content .profil_block_img p {
  color: var(--noir9);
  font-size: 14px;
  margin: 0;
}

.modal_content .message {
  margin-bottom: 10px;
}

.modal_content .hotel_img {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.modal_content .hotel_img_card {
  width: 70px;
  height: 47px;
  background: #f1f1f1;
  border-radius: 10px;
  overflow: hidden;
}

.modal_content .hotel_img_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modal_content .message h4 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.modal_content .message h5 {
  color: var(--noir9);
  font-size: 14px;
}

.modal_content .message p {
  margin: 5px 0;
  font-size: 15px;
}

.modal_content .m_body ul {
  padding: 10px 0;
  margin-left: 18px;
}

.modal_content .m_body ul li {
  padding: 5px 0;
}

@media screen and (max-width: 1024px) {
  .modal_content h3 {
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
