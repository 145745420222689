/*-----------table------------*/
.container_arry {
  height: 100%;
  width: auto;
  overflow: auto;
}
.customers {
  display: inline-table;
  border-collapse: collapse;
  width: 100%;
  height: 100%;
  overflow: auto;
  margin: 0 30px 50px;
}
.customers thead tr {
  display: grid;
  grid-template-columns: 350px 150px 1fr 1fr 80px 1fr 1fr 180px 1fr;
}
.customers tbody tr {
  display: grid;
  grid-template-columns: 350px 150px 1fr 1fr 80px 1fr 1fr 180px 1fr;
  box-shadow: 1px 1px 2px 1px rgb(235 235 235);
}
.customers tr {
  margin-bottom: 15px;
}
.customers th,
.customers td {
  display: flex;
  align-items: center;
  text-align: left;
  min-width: 100px;
}
.customers th p,
.customers td p {
  width: 50%;
  height: 10px;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
.customers th {
  padding-bottom: 15px;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
}
.customers td {
  width: 100%;
  padding: 15px 8px;
}
.customers td.image {
  display: flex;
}
.customers td .card {
  width: 55px;
  height: 35px;
  margin-right: 15px;
  border-radius: 5px;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
.customers td.image p {
  width: 70%;
  height: 15px;
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
.customers .todo {
  width: 80px;
  height: 35px;
  border-radius: 5px;
}

@media screen and (max-width: 900px) {
  .customers {
    margin: 0 24px 50px;
  }
}

@media screen and (max-width: 820px) {
  .customers thead tr {
    grid-template-columns: 1fr;
  }
  .customers tbody tr {
    grid-template-columns: 1fr;
  }
  .customers th {
    display: none;
  }
  .customers .rows {
    display: none;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
