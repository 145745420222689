.modal_content {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  overflow-y: auto;
  padding: 10px 24px 30px;
}

.display_block {
  margin-top: 30px;
}

.display_block_info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

/*------badge------*/
.identity_verif {
  display: flex;
  align-items: center;
  gap: 8px;
}

.identity_verif .icon {
  color: var(--star_color);
}

.identity_verif h2 {
  font-size: 20px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.display_block_info_item p {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 2px;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
@media screen and (max-width: 540px) {
  .m_body::-webkit-scrollbar {
    display: none;
  }
}
