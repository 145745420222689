.content_point {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.content_point .point {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #f1f1f1;
  animation: scaling 0.9s ease-in-out infinite;
}

@keyframes scaling {

  0%,
  100% {
    background-color: #f1f1f1;
  }

  40% {
    background-color: #888;
  }

  50% {
    background-color: #222222;
  }
}

.content_point .point:nth-child(0) {
  animation-delay: 0s;
}

.content_point .point:nth-child(1) {
  animation-delay: 0.2s;
}

.content_point .point:nth-child(2) {
  animation-delay: 0.4s;
}