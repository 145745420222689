.container {
  border: none;
  outline: none;
  width: fit-content;
  cursor: pointer;
  transition: transform 0.3s;
}

.container:active {
  transform: scale(0.9);
}

.copy_text {
  font-size: 15px;
  color: var(--noir6);
  text-decoration: underline;
  text-decoration-style: dotted;
  background-color: transparent;
}

.copy_text:hover {
  background-color: #f1f1f1f1;
}

.copy_svg {
  color: var(--noir);
  margin-left: 5px;
}
