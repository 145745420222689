.home_nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
}

.links_block {
  display: flex;
  align-items: center;
}

.links_block a {
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_1_font-weight);
  padding: 8px 15px;
  border-radius: 30px;
  background: transparent;
}

.links_block a:hover {
  background-color: var(--gray);
}

.links_block a.active {
  background-color: var(--gray);
}

@media screen and (max-width: 743px) {
  .home_nav {
    padding: 20px 24px;
  }
}
