.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 15px;
}

.block {
  padding: 20px 20px 10px;
  background: #fff;
  border-radius: 15px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.2);
}

.block_icon {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.block_icon i {
  color: var(--noir);
  font-size: 20px;
}

.block_icon p {
  font-size: 15px;
}

.block_number {
  display: flex;
  justify-content: flex-start;
}

.block_number i {
  font-size: 15px;
  color: var(--noir);
}

.block_number .number {
  font-size: 22px;
  color: var(--noir);
  font-weight: 100;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

@media screen and (max-width: 900px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 743px) {
  .container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .container {
    grid-template-columns: repeat(1, 1fr);
  }
}
