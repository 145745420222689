@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

:root {
  --noir: #222;
  --noir3: #333;
  --noir4: #444;
  --noir5: #555;
  --noir6: #666;
  --noir7: #777;
  --noir8: #888;
  --noir9: #999;
  --noirc: #ccc;
  --noird: #ddd;
  --gray: #f7f7f7;
  --gray-hover: #ebebeb;
  --border-color: #dddddd;
  --white: #fff;
  --blanc: #fff;
  --rose1: #fd3fe4;
  --rose2: rgb(255, 232, 248);
  --bluetext: #0071eb;
  --blueborder: rgb(187, 187, 255);
  --bluehover: rgb(238, 238, 255);
  --btn_noir1: #4a69bd;
  --btn_noir2: #2c4a9c;
  --box-shadow-border: 0 0 0 2px #000000;

  --sidebar-with: 240px;
  --sidebar-with-hidded: 72px;

  --view-height: 100dvh;
  --navbar-height: 70px;

  --font-family_heading: "Poppins", sans-serif;

  --font_heading_1_font-weight: 600;
  --font_heading_2_font-weight: 700;

  --font_display_1_font-weight: 400;
  --font_display_2_font-weight: 500;
  --font_display_3_font-weight: 600;
}

@media screen and (min-width: 1920px) {
  :root {
    --sidebar-with: 335px;
  }
}

@media screen and (max-width: 1263px) {
  :root {
    --sidebar-with: var(--sidebar-with-hidded);
  }
}

@media screen and (max-width: 743px) {
  :root {
    --sidebar-with: 0;
    --sidebar-with-hidded: 0;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  font-weight: 400;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  line-height: 1.43;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* p,
h1,
h2,
h3,
h4,
h5,
h6,
li,
span,
label {
  color: var(--noir);
}

label {
  -webkit-tap-highlight-color: transparent;
}

input,
textarea,
select,
a {
  background-clip: padding-box;
  -webkit-tap-highlight-color: transparent;
}

input,
textarea,
a {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  appearance: button;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

/* Pour WebKit (Chrome, Safari) */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-clear-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-webkit-search-inner-box {
  -webkit-appearance: none;
  margin: 0;
}

/* Pour Firefox */
input[type="search"] {
  -moz-appearance: none;
}

/* Pour Edge */
input[type="search"]::-ms-clear {
  display: none;
}

@media screen and (max-width: 743px) {
  input,
  textarea {
    font-size: 16px !important;
  }
}
/*------erreur 404----------*/
.erreur404 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.erreur-main {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  padding: 70px;
}
.erreur-main h1 {
  color: rgb(0, 115, 247);
}
.erreur-main h2 {
  font-size: 16px;
  padding: 40px 0px;
}
.erreur-main a {
  text-decoration: none;
  padding: 10px 15px;
  color: #fff;
  font-weight: bolder;
  font-size: 10px;
  border-radius: 30px;
  transition: 0.2s ease-in;
  background-color: rgb(33, 135, 252);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}
.erreur-main a:hover {
  background-color: rgb(0, 90, 194);
}
/*-----pour le modal react datepiker-----*/
.react-calendar {
  width: 100%;
  border: none;
}
.react-calendar button {
  font-size: 14px;
}
.react-calendar__navigation__label,
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button,
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--weekend {
  color: var(--noir);
}
.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  display: flex;
  justify-content: center;
  font-size: 27px !important;
  border-radius: 50%;
}
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar abbr,
.react-calendar button span {
  font-weight: var(--font_display_2_font-weight);
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  padding-bottom: 15px;
}
.react-calendar__navigation__label {
  pointer-events: none;
}
.react-calendar__navigation button:disabled {
  background-color: #fff;
  color: #ccc;
}
.react-calendar__tile:disabled {
  background-color: #fff;
  color: #ccc;
}
.react-calendar__tile--now {
  background: #fff;
  color: var(--noir);
}
.react-calendar__tile--now:enabled:hover {
  background: #f1f1f1;
}
.react-calendar__month-view__days__day--neighboringMonth {
  visibility: hidden;
}
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: var(--noir);
  color: #fff;
}
.react-calendar__tile--rangeStart:enabled:hover,
.react-calendar__tile--rangeStart:enabled:focus,
.react-calendar__tile--rangeEnd:enabled:hover,
.react-calendar__tile--rangeEnd:enabled:focus {
  background: var(--noir);
  color: #fff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus,
.react-calendar__tile--rangeStart,
.react-calendar__tile--rangeEnd {
  background: var(--noir) !important;
  color: #fff !important;
}
.react-calendar__tile--active {
  background: #f1f1f1;
  color: var(--noir);
}
.react-calendar__tile--active:enabled:hover {
  background: #dddddd;
}
@media screen and (max-width: 540px) {
  .react-calendar__month-view__weekdays__weekday {
    padding: 10px 6.6667px;
    padding-bottom: 15px;
  }
  .react-calendar__month-view__days button {
    padding: 10px 6.6667px;
  }
}
