.header {
  padding: 24px 50px 0;
}
.header h1 {
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding-bottom: 30px;
}
.block_link_search {
  margin-bottom: 30px;
}
.link_reservation {
  display: flex;
  gap: 30px;
  border-bottom: 1px solid var(--border-color);
}
.link_reservation a {
  position: relative;
  text-decoration: none;
  font-size: 15px;
  color: var(--noir8);
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 10px;
}

.link_reservation a.active {
  color: var(--noir);
}
.link_reservation a.active::before {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--noir);
}
@media screen and (max-width: 900px) {
  .header {
    padding: 24px 24px 0;
  }
}
@media screen and (max-width: 760px) {
  .link_reservation {
    overflow-x: auto;
    white-space: nowrap;
    margin: 0 -24px;
    padding: 0 24px;
  }
  .link_reservation::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 390px) {
  .link_reservation {
    gap: 20px;
  }
}
@media screen and (max-width: 360px) {
  .link_reservation {
    gap: 15px;
  }
}
