.generate_block button {
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  width: max-content;
  padding: 15px 24px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s;
}

.generate_block button:hover {
  background: var(--btn_noir2);
}

.generate_block button:active {
  transform: scale(0.9);
}

.generate_block button.loading {
  cursor: no-drop;
}

.head_title {
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding-bottom: 10px;
}

.block_list_invoice {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.block_item {
  border-left: 1px solid var(--border-color);
  padding-left: 24px;
  padding-bottom: 20px;
}

.block_item h3 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 18px;
  margin-bottom: 5px;
}

.block_item p {
  font-size: 15px;
  line-height: 2;
}

.block_item a {
  font-size: 15px;
  color: var(--noir);
}

.status_block .incomplet,
.status_block .complet {
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  padding: 6px 8px;
  border-radius: 5px;
  margin-bottom: 8px;
}

.status_block span {
  color: currentColor !important;
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
}

.status_block .incomplet {
  color: rgb(84 89 105);
  background-color: #ebeef1;
}

.status_block .complet {
  color: #006908;
  background-color: #d7f7c2;
}

.checkox_container {
  margin-top: 10px;
}

@media screen and (max-width: 743px) {
  .block_item {
    padding-left: 10px;
  }
}
