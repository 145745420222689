.main {
  min-height: 100vh;
  max-width: 600px;
  margin: 60px auto 50px;
}

.container {
  border: 1px solid var(--border-color);
  border-radius: 16px;
  padding: 24px;
}

@media screen and (max-width: 600px) {
  .container {
    border: none;
    border-radius: none;
  }

  .main {
    margin-top: 0px;
  }
}

.head h4 {
  font-size: 25px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  text-align: center;
  padding-bottom: 24px;
}

.head p {
  font-size: 15px;
}

.connexion_form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 25px 0 15px;
}

.connexion_form .connexion_echec {
  display: flex;
  border-radius: 5px;
  overflow: hidden;
}

.connexion_form .connexion_echec .icon {
  padding: 20px;
  background-color: #ffa8a8;
  color: #fa5252;
}

.connexion_form .connexion_echec .icon i {
  font-size: 30px;
}

.connexion_form .connexion_echec .notif {
  padding: 20px;
  width: 100%;
  background: #fff5f5;
}

.connexion_form .connexion_echec .notif h1 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  color: var(--noir4);
}

.connexion_form .connexion_echec .notif p {
  font-size: 15px;
  color: var(--noir4);
  text-align: left;
}

/*----- .reussit_conexion-----*/
.connexion_form .reussit_conexion {
  display: flex;
  border-radius: 5px;
  margin-bottom: 20px;
  overflow: hidden;
}

.connexion_form .reussit_conexion .icon {
  padding: 20px;
  background-color: rgb(166, 228, 255);
  color: rgb(0, 167, 238);
}

.connexion_form .reussit_conexion .icon i {
  font-size: 30px;
}

.connexion_form .reussit_conexion .notif {
  padding: 20px;
  width: 100%;
  background: rgb(229, 246, 253);
}

.connexion_form .reussit_conexion .notif h1 {
  font-weight: var(--font_display_2_font-weight);
  padding: 0;
  font-size: 15px;
  color: var(--noir4);
}

.connexion_form .reussit_conexion .notif p {
  font-size: 15px;
  padding: 0;
  color: var(--noir4);
  text-align: left;
}

.connexion_form label {
  display: inline-block;
  margin-bottom: 5px;
  cursor: pointer;
}

.connexion_form input {
  box-sizing: border-box;
  padding: 0 15px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.connexion_form input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.connexion_form .erreur input {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 1px inset;
}

.connexion_form .erreur input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.connexion_form a {
  margin-top: 20px;
  color: var(--noir);
  font-size: 15px;
  text-decoration: none;
  cursor: pointer;
}

.connexion_form input::placeholder {
  font-size: 15px;
  color: var(--noir9);
}

.content_input p {
  font-size: 13px;
  color: var(--noir6);
  margin-top: 3px;
}

.password {
  position: relative;
}

.password ion-icon {
  position: absolute;
  right: 10px;
  top: 42px;
  font-size: 20px;
  cursor: pointer;
}

.connexion_form span.erreur {
  color: rgb(193, 53, 21);
  font-size: 15px;
}

span.forgot {
  text-decoration: underline;
  cursor: pointer;
}

.contrat p {
  font-size: 12px;
  color: var(--noir);
  margin-top: 5px;
}

.contrat p strong {
  color: var(--noir);
  font-weight: var(--font_display_3_font-weight);
}

.contrat p a {
  text-decoration: underline;
  font-size: 12px;
  color: var(--noir);
}

.btn_submit button {
  position: relative;
  width: 100%;
  height: 50px;
  margin-top: 30px;
  margin-bottom: 20px;
  background: var(--btn_noir1);
  border: none;
  outline: none;
  color: #fff;
  padding: 0 20px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.btn_submit button:hover {
  background: var(--btn_noir2);
}

.btn_submit button:active {
  transform: scale(0.9);
}

.btn_submit button.disable {
  opacity: 0.4;
  cursor: no-drop;
}

.btn_submit button.disable:hover {
  background: var(--button1);
}
