.container {
  height: 100vh;
}
.container_load_liste {
  margin-top: 100px;
}

.main {
  padding: 0 50px 100px;
}
.infos_block {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding: 10px 0 30px;
  border-bottom: 1px solid var(--border-color);
}
.infos_block_content h2 {
  font-size: 40px;
  color: var(--noir3);
  font-weight: var(--font_heading_2_font-weight);
}
.infos_block_content p {
  color: var(--noir6);
}

.detail_block {
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  padding-top: 40px;
}
.detail_block_content h2 {
  font-size: 25px;
  color: var(--noir3);
  font-weight: var(--font_heading_2_font-weight);
}
.detail_block_content p {
  font-size: 14px;
  color: var(--noir6);
}
@media screen and (max-width: 950px) {
  .main {
    padding: 0 24px 100px;
  }
}
@media screen and (max-width: 743px) {
  .infos_block,
  .detail_block {
    flex-direction: column;
  }
  .infos_block_content h2 {
    font-size: 30px;
  }
  .detail_block_content h2 {
    font-size: 20px;
  }
}
