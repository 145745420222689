.modal_Snackbar {
  position: fixed;
  display: flex;
  align-items: center;
  width: max-content;
  gap: 5px;
  bottom: 30px;
  left: 25px;
  padding: 13px 24px;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  border-radius: 12px;
  border: 1px solid rgb(221, 221, 221);
  background-color: rgb(255, 255, 255);
  z-index: 5000;
  animation-duration: 600ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
  animation-name: modal_content_slideOutn;

}

.modal_Snackbar.active {
  animation-name: modal_content_slideIn;
}

@keyframes modal_content_slideIn {
  from {
    opacity: 0;
    transform: translateY(250px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes modal_content_slideOutn {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translateY(250px);
  }
}

.modal_Snackbar svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #008a05;
}

.modal_Snackbar p {
  font-size: 15px;
}

@media screen and (max-width: 700px) {
  .modal_Snackbar {
    width: max-content;
    bottom: 80px;
    left: 50%;
  }

  @keyframes modal_content_slideIn {
    from {
      opacity: 0;
      transform: translate(-50%, 250px);
    }

    to {
      opacity: 1;
      transform: translate(-50%, 0);
    }
  }

  @keyframes modal_content_slideOutn {
    from {
      opacity: 1;
      transform: translate(-50%, 0);
    }

    to {
      opacity: 0;
      transform: translate(-50%, 250px);
    }
  }
}