/*-------content_list-------*/
.content_list {
  height: 100%;
  overflow: auto;
  padding-bottom: 50px;
}
.content_list .block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
  width: 100%;
  padding: 20px 30px;
  margin-bottom: 2px;
}
.content_list .card {
  width: 55px;
  height: 40px;
  background-color: #ddd;
  border-radius: 5px;
}
.content_list .content {
  width: 100%;
}
.content_list h3 {
  width: 90%;
  height: 10px;
  background-color: #ddd;
}
.content_list p {
  margin-top: 10px;
  width: 70%;
  height: 10px;
  background-color: #ddd;
}
.skeleton {
  opacity: 0.7;
  animation: skeleton-loading 1s linear infinite alternate;
}
@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
