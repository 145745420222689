.seting_container {
  padding: 40px 160px 50px;
  min-height: 100vh;
}

.seting_container h1 {
  font-size: 20px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding: 10px 0 30px;
}

/*----seting_box------*/
.seting_box {
  display: grid;
  grid-template-columns: 1fr 5px 1fr;
  grid-gap: 50px;
}

.separate {
  height: 100%;
  background-color: #ececec;
}

.securite_1 .p_head {
  font-size: 16px;
  padding-bottom: 20px;
}

.gr_code_block {
  display: flex;
  justify-content: center;
}

.gr_code_block_img {
  width: 200px;
  height: 210px;
  background-color: #ccc;
}

.gr_code_block_img img {
  width: 100%;
  height: 100%;
}

.block_input {
  position: relative;
  margin: 5px 0 15px;
}

.securite_1 input {
  box-sizing: border-box;
  padding: 0 15px;
  margin-top: 5px;
  width: 100%;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}

.securite_1 input::placeholder {
  color: var(--noirc);
}

.securite_1 input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.securite_1 .erreur input {
  box-shadow: rgb(193, 53, 21) 0px 0px 0px 1px inset;
  font-size: 14px;
}

.securite_1 .erreur input:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.securite_1 span.erreur {
  color: rgb(193, 53, 21);
  font-size: 14px;
}

.buttoms button,
.buttoms button.loading {
  padding: 8px 10px;
  margin-top: 10px;
  height: 48px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border: none;
  outline: none;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.buttoms button {
  background: var(--btn_noir1);
}

.buttoms button:hover {
  background: var(--btn_noir2);
}

.buttoms button:active {
  transform: scale(0.9);
}

.buttoms button.loading {
  background: rgb(226, 226, 226);
  cursor: no-drop;
}

.buttoms button.loading:hover {
  background: rgb(226, 226, 226);
}

.p_error {
  margin-top: 24px;
}

@media screen and (min-width: 1440px) {
  .seting_container {
    max-width: var(--maxWith);
    margin: 0 auto;
  }
}

@media screen and (max-width: 1271px) {
  .seting_container {
    padding: 40px 50px 50px;
  }
}

@media screen and (max-width: 950px) {
  .seting_container {
    padding: 20px 24px 50px;
  }
}

@media screen and (max-width: 800px) {
  .seting_container h1 {
    font-size: 22px;
  }

  .seting_box {
    display: block;
  }

  .AllAccount_setings {
    display: none;
  }
}

@media screen and (max-width: 743px) {
  .buttoms button,
  .buttoms button.loading {
    width: 100%;
  }
}
