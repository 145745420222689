.container {
  display: flex;
  align-items: center;
}

.block_today {
  margin-left: -15px;
}

.block_today a {
  display: flex;
  align-items: flex-end;
  height: max-content;
  gap: 10px;
  text-decoration: none;
  color: var(--noir);
  padding: 8px 15px;
  border-radius: 30px;
  background: transparent;
}

.block_today a:hover {
  background-color: var(--gray);
}

.block_today a.active {
  background-color: var(--gray);
}

.block_today a svg {
  height: 24px;
  width: 24px;
  stroke: currentColor;
}

.block_today a span {
  display: inline-block;
  font-weight: var(--font_display_1_font-weight);
  color: var(--noir);
  font-size: 15px;
}
