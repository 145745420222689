.main {
  display: flex;
  flex-direction: column;
  margin-left: var(--sidebar-with);
}

.main.hidded {
  margin-left: var(--sidebar-with-hidded);
}

@media screen and (max-width: 743px) {
  .main.hidded {
    margin-left: 0;
  }
}
