.main {
  height: calc(var(--view-height) - var(--navbar-height));
  border-top: 1px solid var(--border-color);
  overflow: hidden;
}

.main {
  display: grid;
  grid-template-columns: 350px 1fr;
}

/*-----calendar--------*/
.droit {
  height: 100%;
  overflow: auto;
}

.loading {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  padding: 0 10px 50px;
}

.calendar_head {
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
}

.open_modal button {
  position: relative;
  background: transparent;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  display: none;
  justify-content: center;
  align-items: center;
  transition: 0.3s all;
}

.open_modal button:hover {
  background-color: var(--gray);
}

.open_modal button:active {
  transform: scale(0.9);
}

.open_modal button svg {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: var(--noir);
  stroke-width: 3;
}

.actions_btn {
  display: flex;
  gap: 10px;
}

.datesSelectionner {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}

.datesSelectionner p {
  color: var(--noir);
  font-size: 15px;
}

.actions_btn button {
  background-color: transparent;
  padding: 10px 25px;
  border-radius: 8px;
  font-size: 13px;
  border: 1px solid var(--noir);
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  transition: 0.3s;
}

.actions_btn button.disabled {
  border-color: var(--noirc);
  color: var(--noirc);
  cursor: no-drop;
}

.calendar p.notice_date {
  color: var(--noir9);
  font-size: 13px;
}

.calendar .date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
  font-size: 18px;
  border: 1px solid var(--border-color);
  padding: 16px;
  margin-left: -1px;
  margin-top: -1px;
}

.calendar .date.date_bloquer {
  color: var(--noir9);
  position: relative;
}

.calendar .date.date_bloquer::before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--noir);
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: rotate(-30deg);
}

.select_annonce {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 70vh;
}

.select_annonce p {
  color: var(--noir6);
}

.select_annonce button {
  display: none;
  background-color: transparent;
  margin-top: 20px;
  padding: 10px 25px;
  border-radius: 8px;
  font-size: 13px;
  border: 1px solid var(--border-color);
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  transition: 0.3s;
}

.select_annonce button:hover,
.actions_btn button:hover {
  background-color: var(--gray);
}

.select_annonce button:active,
.actions_btn button:active {
  transform: scale(0.9);
}

@media screen and (max-width: 912px) {
  .main {
    display: block;
  }

  .open_modal button {
    display: flex;
  }

  .calendar .date {
    height: 100px;
    font-size: 17px;
  }

  .select_annonce button {
    display: flex;
  }
}

@media screen and (max-width: 743px) {
  .actions_btn button {
    padding: 10px;
  }

  .calendar .date {
    height: 70px;
    font-size: 15px;
    padding: 10px;
  }

  .calendar p.notice_date {
    font-size: 11px;
  }
}

@media screen and (max-width: 500px) {
  .calendar .date.date_bloquer::before {
    top: 54%;
    transform: rotate(-45deg);
  }

  .calendar p.notice_date {
    display: none;
  }
}

@media screen and (max-width: 418px) {
  .datesSelectionner p {
    font-size: 11px;
  }

  .calendar .date {
    height: 50px;
  }
}
