/*---componete-photo-----*/
.block {
  padding-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.head h3 {
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.head a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--noir);
  padding: 6px 10px;
  margin-top: -6px;
  margin-right: -10px;
  border-radius: 5px;
}

.head a span {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
}

.head a:hover {
  background-color: var(--gray);
}

.head a:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 0 30px;
}

.main p {
  color: #717171;
  font-size: 14px;
}

.main p strong {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

/*-----pages securite de voyageurs--------*/
.dispositif {
  margin-bottom: 100px;
}

.dispositif h3 {
  padding-top: 30px;
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.container_equip h4 {
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding-top: 50px;
}

.checkbox_container {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  padding: 24px 0;
}

.checkbox_container:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.checkbox_container label {
  width: 100%;
  font-size: 16px;
  color: var(--noir);
  cursor: pointer;
}

.checkbox_container p {
  color: #717171;
  font-size: 14px;
  margin-top: 8px;
}

/*-----footer----*/
.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
}

.footer_block {
  margin-left: calc(380px + var(--sidebar-with-hidded));
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 30px;
  background: #fff;
  border-top: 1px solid var(--border-color);
}

.footer button.retour {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  padding: 10px;
  margin-left: -10px;
  color: var(--noir);
  text-decoration: underline;
  cursor: pointer;
}

.footer button.retour:hover {
  background: var(--gray);
}

.footer button.next {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  width: 130px;
  height: 50px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.footer button.next:hover {
  background: var(--btn_noir2);
}

.footer button.next:active {
  transform: scale(0.9);
}

.footer button.btn_loading {
  width: 130px;
  height: 50px;
  border: none;
  outline: none;
  background: rgb(226, 226, 226);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

@media screen and (max-width: 743px) {
  .footer_block {
    margin-left: 0;
    padding: 16px 24px;
  }
}
