.form_block {
  padding: 30px 0;
}
.current_location button {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 10px 24px;
  border: 1px solid #222222;
  border-radius: 8px;
  outline: none;
  background: transparent;
  cursor: pointer;
  transition: 0.3s;
}
.current_location button:hover {
  background-color: rgb(247, 247, 247);
}
.current_location button:active {
  transform: scale(0.9);
}
.current_location .position {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background-color: rgb(247, 247, 247);
  border-radius: 50%;
}
.current_location .position svg {
  display: block;
  height: 15px;
  width: 15px;
  fill: currentcolor;
}
.current_location button p {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}
.form_block label {
  display: inline-block;
  font-size: 16px;
  color: var(--noir3);
  cursor: pointer;
}
.form_block .inputs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 15px;
}
.form_block .block {
  width: 100%;
  margin-top: 20px;
}
.form_block .block select {
  background-color: var(--gray);
  border-color: var(--gray);
  color: #ddd;
  cursor: no-drop;
}
.form_block input::placeholder {
  font-size: 15px;
  color: var(--noir9);
}
.form_block input:focus,
.form_block select:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

/*------autocomplete-----*/
.city {
  position: relative;
}
.block_edit {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  margin: 5px 0;
  font-size: 15px;
  padding: 0 15px;
  border-radius: 8px;
  border: none;
  outline: none;
  overflow: hidden;
  white-space: nowrap;
  background: transparent;
  color: var(--noir);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
}
.city.active .block_edit {
  cursor: default;
}
.city.active .block_edit {
  background: #fff;
}
.block_edit .search_content {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  margin-right: 10px;
  height: 100%;
  color: rgb(34, 34, 34);
}
.block_edit svg.maps {
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--noir3);
}
.cite_search {
  position: absolute;
  z-index: 10;
  top: 85px;
  left: 0px;
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
  border: 1px solid rgb(221, 221, 221);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 20%) 0px 6px 20px;
  background: #fff;
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease-in-out;
}
.city.active .cite_search {
  visibility: visible;
  opacity: 1;
}
.cite_search button.name {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 7px 24px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
}
.cite_search button.name:hover {
  background-color: rgb(247, 247, 247);
}
.cite_search button i {
  font-size: 13px;
  padding: 13px 15px;
  color: var(--noir5);
  background-color: rgb(241, 241, 241);
  border: 1px solid rgba(176, 176, 176, 0.2);
  border-radius: 5px;
}
.cite_search button p {
  font-size: 15px;
  text-align: left;
}
/*-------reviewMaps------*/
.reviewMaps_container {
  position: relative;
  width: 100%;
  height: 400px;
  background: #ccc;
  margin: 24px 0;
  border-radius: 15px;
  overflow: hidden;
}
.reviewMap_block {
  width: 100%;
  height: 100%;
}
.marker {
  position: absolute;
  width: 300px;
  max-width: 100%;
  height: 300px;
  max-height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
}
.marker svg.maps_marker {
  display: block;
  height: 45px;
  width: 45px;
  stroke: #000;
  fill: var(--noir);
}
