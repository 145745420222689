.block_content {
  padding: 24px;
}

/*---------profil--------------*/
.profil {
  display: flex;
  justify-content: center;
  align-items: center;
}
.profil_block {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.4);
  background-color: #f4fbff;
  padding: 30px 20px;
}
.profil_image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
  width: 180px;
  background-color: #fff;
  border-radius: 46px;
  margin-bottom: 30px;
  box-shadow: 0 0 6px rgba(51, 51, 51, 0.363);
  overflow: hidden;
}
.profil_image svg {
  display: block;
  height: 70%;
  width: 70%;
  fill: #717171;
  background: transparent;
  stroke-width: 3;
}
.profil_image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profil_label {
  background-color: rgb(33, 135, 252);
  color: #fff;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  transition: 0.2s ease-in;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}
.profil_label:hover {
  background-color: rgb(0, 108, 231);
}
.form_profil input[type="submit"] {
  display: block;
  color: #fff;
  margin-top: 20px;
  padding: 10px 25px;
  background: rgb(221, 118, 0);
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  text-transform: uppercase;
  position: relative;
  outline: none;
  border: none;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}
.form_profil input[type="submit"]:hover {
  background-color: rgb(190, 102, 0);
}
.profil_text {
  margin-top: 15px;
}
.profil_text ul li {
  list-style: none;
  color: #585656;
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
  text-align: left;
}
