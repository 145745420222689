.container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.checkox_container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 30px 0;
}
.text {
  display: flex;
  flex-direction: column;
}

.checkox_container p {
  color: #717171;
  font-size: 13px;
  margin: 0;
}
