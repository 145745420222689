.container {
  min-height: 100dvh;
  display: grid;
  grid-template-columns: 340px 1fr;
}

.left_block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  background-color: #b3d4fc33;
  padding: 24px;
}

.left_block_bottom p {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir8);
}

.login_position {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  width: 500px;
  padding: 50px 30px;
  border-radius: 5px;
  background: #fff;
}

.login_head {
  margin-bottom: 25px;
}

.login_head h1 {
  font-weight: var(--font_display_3_font-weight);
  font-size: 24px;
  color: var(--noir);
  margin-bottom: 10px;
}

.login_head p {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  color: var(--noir8);
}

.login_inputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.login label {
  color: var(--noir);
  font-size: 16px;
}

.login input {
  width: 100%;
  margin-top: 5px;
  height: 50px;
  padding: 0 10px;
  outline: none;
  border: rgb(197, 197, 197) 1px solid;
  border-radius: 5px;
  font-size: 16px;
  color: var(--noir);
  transition: 0.2s ease-in;
  background-clip: padding-box;
}

.login input:focus {
  border: 1px solid #d036ff33;
  box-shadow: 0 0px 1px 3px #cd2bff33;
}

.login input::placeholder {
  color: var(--noirc);
}

.login .error {
  text-align: left;
  margin-top: 5px;
  color: #bf1650;
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
}

.login_submit,
.buttonload {
  display: block;
  width: 100%;
  height: 45px;
  margin: 10px 0;
  outline: none;
  border-radius: 5px;
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  border: none;
  margin-top: 35px;
  color: #fff;
  transition: 0.2s ease-in;
}

.login_submit {
  background-color: #2187fc;
}

.login_submit:hover {
  background-color: #136ed6;
}

.buttonload {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e2e2e2;
}

/*button connexion*/
.connect {
  width: 100%;
  text-decoration: none;
  display: block;
  text-align: center;
  font-size: 17px;
  padding: 10px;
  margin: 10px 0;
  border-radius: 30px;
  border: 1px solid #2187fc;
  color: #2187fc;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
  transition: 0.2s ease-in;
}

.connect:hover {
  background: #2187fc;
  color: #fff;
  text-decoration: none;
}

@media screen and (max-width: 840px) {
  .container {
    display: block;
    padding-top: 100px;
  }

  .left_block {
    display: none;
  }
}

@media screen and (max-width: 700px) {
  .login_position {
    align-items: flex-start;
  }

  .login {
    padding: 0 24px;
    background: none;
  }

  .login_head {
    text-align: center;
    margin-bottom: 24px;
  }
}
