.profil_block {
  display: grid;
  grid-template-columns: 150px 1fr 240px;
  grid-gap: 30px;
}

.profil_photo .image {
  display: block;
  text-decoration: none;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #ddd;
  overflow: hidden;
}

.profil_photo .image svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #999;
  background: #fff;
  stroke-width: 3;
}

.profil_photo .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*------badge------*/
.badge .identity_verif {
  display: flex;
  align-items: center;
  gap: 8px;
}

.badge .icon {
  color: var(--star_color);
}

.badge h2 {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  line-height: 40px;
}

.category_block p {
  font-size: 14px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.about_me_desc p {
  font-size: 15px;
}

.verify_button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  margin: 20px 0;
  border: 1px solid var(--noir);
  color: var(--noir);
  background: transparent;
  padding: 0 20px;
  height: 48px;
  border-radius: 8px;
  cursor: default;
}

.verify_button span {
  display: inline-block;
  color: currentColor;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.verify_button.disabled {
  border: 1px solid var(--noirc);
  color: var(--noirc);
  cursor: default;
}

/*----verify------*/
.verify h2 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 10px;
}

.verify h2 strong {
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
}

.verify .verify_item {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 5px 0;
}

.verify h3 {
  font-size: 14px;
  color: var(--noir8);
}

.verify button {
  border: none;
  background: transparent;
  margin-top: 20px;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  text-decoration: underline;
  cursor: pointer;
}

.verify.active_modif button {
  color: rgb(226, 226, 226);
  cursor: no-drop;
}

@media screen and (max-width: 950px) {
  .profil_block {
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 743px) {
  .profil_photo {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .profil_photo .image {
    width: 100px;
    height: 100px;
  }

  .badge h2 {
    font-size: 20px;
  }

  .verify h2 {
    font-size: 18px;
  }

  .verify h2 strong {
    font-size: 20px;
  }
}
