.container {
  margin-bottom: 24px;
}

.container h2 {
  font-weight: var(--font_display_2_font-weight);
  font-size: 20px;
  color: var(--noir);
}

.container p {
  font-size: 15px;
  color: var(--noir);
  margin-top: 5px;
}

.processing,
.echec {
  padding: 20px;
  border-radius: 10px;
}

.succeeded {
  display: flex;
  align-items: center;
  gap: 20px;
  background: rgb(229, 246, 253);
}

.processing {
  background: var(--gray);
}

.echec {
  display: flex;
  gap: 15px;
  background: #fff5f5;
}

.echec a {
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
}

@media screen and (max-width: 743px) {
  .container h2 {
    font-size: 15px;
  }

  .container p {
    font-size: 14px;
  }
}
