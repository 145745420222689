.container button {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
  background-color: transparent;
  border: 1px solid var(--noir);
  border-radius: 8px;
  margin-top: 8px;
  padding: 10px 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  transition: transform 0.3s;
}

.container button:hover {
  background-color: var(--gray);
}

.container button:active {
  transform: scale(0.9);
}
