.block_content {
  padding: 24px 30px;
}

.block_content > p {
  padding-bottom: 10px;
  font-size: 23px;
  color: var(--noir);
}
/*----------les bouton-droite-----------*/

.bouton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.bouton a {
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 20px;
  text-align: center;
  background: rgb(214, 214, 214);
  color: rgb(83, 83, 83);
  font-size: 14px;
  transition: 0.1s ease-in;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}
.bouton a:hover {
  background: rgb(223, 223, 223);
}
.bouton a.active {
  background: rgb(243, 243, 243);
  cursor: no-drop;
}

@media (max-width: 1100px) {
  .bouton a {
    padding: 10px 30px;
  }
}
@media (max-width: 820px) {
  .bouton {
    flex-direction: column;
  }
  .bouton a {
    width: 100%;
  }
}
/*--------text------*/
.container_text {
  padding: 20px;
  text-align: center;
}
.container_text span {
  color: rgb(33, 135, 252);
}
.container_text span.modif {
  color: rgb(221, 118, 0);
}
.container_text h2 {
  color: rgb(128, 128, 128);
}
.container_text p {
  font-size: 15px;
}
/*--------form------*/
.form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
.form_position {
  flex: 1;
}
.form label {
  margin-bottom: 0;
  color: var(--noir);
  font-size: 16px;
}
.form .etoil {
  font-size: 17px;
  color: rgb(255, 44, 44);
}
.form .error {
  margin-left: 10px;
  text-transform: lowercase;
  letter-spacing: 1px;
  color: #bf1650;
  font-size: 13px;
  width: 100%;
}
.form input,
.form select {
  width: 100%;
  height: 45px;
  padding: 0 10px;
  margin: 2px 0 15px;
  outline: none;
  box-sizing: border-box;
  border: rgb(197, 197, 197) 1px solid;
  border-radius: 5px;
  font-size: 16px;
  color: var(--noir);
  transition: 0.2s ease-in;
  background-clip: padding-box;
}
.form input::placeholder {
  color: var(--noir9);
}
.form textarea {
  width: 100%;
  background-clip: padding-box;
  padding: 10px;
  margin: 3px 0 10px;
  box-sizing: border-box;
  outline: none;
  resize: vertical;
  line-height: 1.5;
  border: rgb(197, 197, 197) 1px solid;
  border-radius: 5px;
  font-size: 15px;
  color: var(--noir);
  transition: 0.2s ease-in;
}
.form textarea:focus {
  border: 1px solid #d036ff33;
  box-shadow: 0 0px 1px 3px #cd2bff33;
}
.form select > option {
  font-size: 13px;
}
.form input:focus {
  border: 1px solid #d036ff33;
  box-shadow: 0 0px 1px 3px #cd2bff33;
}
.form select:focus {
  border: 1px solid #d036ff33;
  box-shadow: 0 0px 1px 3px #cd2bff33;
}
.form input::placeholder {
  color: var(--noir9);
}
.password_show {
  position: relative;
}
.password_show i {
  position: absolute;
  top: 40px;
  right: 20px;
}
form .boutons,
.button_modif {
  display: flex;
  justify-content: center;
}
.boutons button {
  display: block;
  -webkit-appearance: button;
  background-position: 50%;
  text-transform: uppercase;
  color: #fff;
  width: 30%;
  height: 45px;
  text-align: center;
  background-color: rgb(33, 135, 252);
  cursor: pointer;
  border-radius: 8px;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  outline: none;
  border: none;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}
.boutons button:hover {
  background-color: rgb(0, 100, 214);
}
.button_modif button {
  display: block;
  -webkit-appearance: button;
  background-position: 50%;
  text-transform: uppercase;
  color: #fff;
  width: 30%;
  height: 45px;
  text-align: center;
  background-color: rgb(33, 135, 252);
  cursor: pointer;
  border-radius: 8px;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  outline: none;
  border: none;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
  background-color: rgb(221, 118, 0);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}
.button_modif button:hover {
  background-color: rgb(190, 102, 0);
}

@media screen and (max-width: 700px) {
  .form {
    display: block;
  }
  .boutons button,
  .button_modif button {
    width: 100%;
  }
}
