.container {
  min-height: 100vh;
  max-width: 800px;
}
.container_load_liste {
  margin-top: 100px;
}
.main {
  padding: 0 50px 100px;
}
.net p,
.detail p,
.infos p {
  color: var(--noir8);
}
.net {
  margin: 40px 0 50px;
}
.net h1 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin-bottom: 10px;
}
.net h2 {
  font-size: 45px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}
.detail {
  display: flex;
  gap: 50px;
}
.detail h2 {
  font-size: 20px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir4);
  margin-bottom: 10px;
}
.detail_block {
  display: flex;
  align-items: center;
  gap: 10px;
}
.detail_block span {
  display: block;
  width: 16px;
  height: 16px;
}
.detail_block span.black {
  background-color: var(--noir);
}
.detail_block span.gray {
  background-color: #b0b0b0;
}
.infos h3 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin: 50px 0 24px;
}
.infos .infos_block {
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  border-top: 1px solid var(--border-color);
}
.infos_block p.complet,
.infos_block p.incomplet {
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  padding: 5px 8px;
  margin-top: 5px;
  border-radius: 5px;
}
.infos_block p.complet span,
.infos_block p.incomplet span {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
}
.infos_block p.incomplet {
  color: rgb(84 89 105);
  background-color: #ebeef1;
}
.infos_block p.complet {
  color: #006908;
  background-color: #d7f7c2;
}

@media screen and (max-width: 950px) {
  .main {
    padding: 0 24px 100px;
  }
  .net h2 {
    font-size: 35px;
  }
  .detail h2 {
    font-size: 18px;
    font-weight: var(--font_display_2_font-weight);
  }
}
