.modal_content {
  display: flex;
  flex-direction: column;
  width: 1150px;
  max-width: 100%;
  max-height: 95vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.modal_content .m_body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0 100px 24px;
}

/* container_load */
.container_load {
  display: flex;
  justify-content: center;
  align-items: center;
}

/*-----profil_box----*/
.profil_box {
  display: flex;
  flex-direction: column;
}

.profil_infos {
  padding: 20px 50px 0;
}

@media screen and (max-width: 1000px) {
  .modal_content .m_body {
    padding: 0 30px 24px;
  }
}

@media screen and (max-width: 768px) {
  .profil_infos {
    padding: 20px 0 0;
  }
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }

  .modal_content .m_body {
    padding: 0 24px 24px;
  }
}
