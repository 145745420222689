.voyage_container {
  display: flex;
  flex-direction: column;
  height: calc(var(--view-height) - var(--navbar-height));
  overflow: hidden;
}

.no_result {
  margin-top: 60px;
  text-align: center;
  padding: 0 24px;
}

.no_result h2 {
  color: var(--noir);
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  line-height: 33px;
}

.no_result p,
.no_result a {
  color: var(--noir4);
}

.no_result a:hover {
  text-decoration: none;
}

.container_load_liste {
  margin-top: 70px;
}

/*-----------table------------*/
.container_arry {
  width: 100%;
  height: 100%;
  padding: 0 30px 50px;
  overflow: auto;
}

.container_arry .customers {
  position: relative;
  border-collapse: collapse;
  width: 100%;
}

.customers thead {
  position: sticky;
  top: 0;
  z-index: 10;
}

.customers th,
.customers tr {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  background-color: #fff;
}

.customers th,
.customers td {
  text-align: left;
  flex: 1;
  min-width: 170px;
}

.customers th {
  font-size: 13px;
  text-transform: uppercase;
  font-weight: var(--font_display_2_font-weight);
  padding: 5px 8px;
  color: var(--noir3);
}

.customers td {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 8px;
  color: rgb(114, 114, 114);
  font-size: 15px;
  word-break: break-all;
}

.customers tr:nth-child(even) {
  background-color: #fdfdfd;
}

.customers tr:hover,
.customers tr:hover td.image,
.customers td.image:hover {
  background-color: #f1f1f1;
}

.customers th.image,
.customers td.image {
  position: sticky;
  left: -30px;
  top: 0;
  background-color: #fff;
  flex: 3;
  min-width: 270px !important;
}

.customers td.image {
  padding: 0 !important;
}

.customers td.image a {
  width: 100%;
  display: flex;
  padding: 15px 8px;
  text-decoration: none;
  color: var(--noir);
}

.customers td .card {
  width: 55px;
  height: 35px;
  background-color: #f1f1f1;
  margin-right: 15px;
  border-radius: 5px;
  overflow: hidden;
}

.customers td .card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customers td.image p {
  font-weight: var(--font_display_2_font-weight);
  font-size: 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.container_arry .statut {
  display: flex;
  align-items: center;
}

.demande {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}

.demande svg {
  display: block;
  height: 16px;
  width: 16px;
}

.demande svg.no {
  fill: rgb(221, 221, 221);
}

.demande svg.yes {
  fill: rgb(0, 138, 5);
}

.demande span {
  font-size: 14px;
  color: var(--noir);
}

.container_arry .action {
  display: flex;
  align-items: center;
}

.container_arry .action button.first {
  position: relative;
  background: var(--white);
  border: none;
  box-shadow: rgb(34 34 34) 0px 0px 0px 1px inset;
  outline: none;
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir3);
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}

.container_arry .action button.first:hover {
  background: var(--gray);
}

.container_arry .action button.first:active {
  transform: scale(0.9);
}

.container_arry .action button.first.active {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.container_arry .action .option {
  position: absolute;
  top: -45px;
  right: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 16px;
  border-radius: 8px;
  width: 240px;
  padding: 8px 0;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 16px;
  overflow: hidden;
  z-index: 1;
}

.container_arry .action .option a,
.container_arry .action .option h3 {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  outline: none;
  font-size: 15px;
  text-align: left;
  text-decoration: none;
  background: #fff;
  color: var(--noir3);
  cursor: pointer;
}

.container_arry .action .option a:hover,
.container_arry .action .option h3:hover {
  background: #f1f1f1;
}

@media screen and (max-width: 950px) {
  .container_arry {
    padding: 0 24px 50px;
    margin-top: 0;
  }

  .customers th.image,
  .customers td.image {
    left: -24px;
  }
}

@media screen and (max-width: 820px) {
  .customers th {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .customers .rows,
  .customers .statut,
  .customers .action {
    display: none;
  }

  .customers td.image a {
    align-items: center;
  }
  .customers tr:hover,
  .customers tr:hover td.image,
  .customers td.image:hover {
    background-color: white;
  }
}
