.container_title button {
  background: transparent;
  border: none;
  outline: none;
  color: var(--noir);
  transition: 0.3s;
}

.container_title button:active {
  transform: scale(0.9);
}

.modal_content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 550px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

@media screen and (max-width: 743px) {
  .m_head {
    min-height: 48px;
  }
}

.m_body {
  overflow: auto;
  padding: 24px;
}

.m_body p {
  font-size: 15px;
  color: var(--noir);
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_body {
    padding: 16px 24px 30px;
  }

  .m_body::-webkit-scrollbar {
    display: none;
  }
  .m_body p {
    font-size: 13px;
  }
}
