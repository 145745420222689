/*------loading-trois points-------*/
.loading-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loading-point {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50vh;

}

.content-point {
  display: flex;
  justify-content: center;
  gap: 4px;
}

.content-point .point {
  height: 7px;
  width: 7px;
  border-radius: 50%;
  background-color: #f1f1f1;
  animation: scaling 0.9s ease-in-out infinite;
}

@keyframes scaling {

  0%,
  100% {
    background-color: #f1f1f1;
  }

  40% {
    background-color: #888;
  }

  50% {
    background-color: #222222;
  }
}

.content-point .point:nth-child(0) {
  animation-delay: 0s;
}

.content-point .point:nth-child(1) {
  animation-delay: 0.2s;
}

.content-point .point:nth-child(2) {
  animation-delay: 0.4s;
}