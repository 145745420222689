.container {
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-top: 24px;
}

.container_block {
  padding-left: 24px;
  padding-bottom: 24px;
  border-left: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.p_date {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir8);
}

.container_block h2 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  margin: 5px 0;
}

.p_profil {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 10px;
}

.modified_block p {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
  font-size: 15px;
  margin: 3px 0;
}

.modified_block p span {
  font-size: 14px;
  color: var(--noir8);
}

.img_container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 10px;
}

.img_block {
  background-color: var(--gray);
  height: 150px;
  border-radius: 12px;
  overflow: hidden;
}

.img_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modified_block p.img_caption {
  font-size: 12px;
  margin-top: 3px;
}

.footer {
  margin-top: 20px;
}

.footer a {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

@media screen and (max-width: 743px) {
  .container_block {
    padding-left: 0;
    border-left: none;
  }
}

@media screen and (max-width: 390px) {
  .img_block {
    height: 100px;
    border-radius: 8px;
  }
}
