.select_elem {
  position: relative;
  width: 100%;
}
.select_elem::after {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  top: 35%;
  right: 20px;
  transform: rotate(45deg);
  border-bottom: 2px solid var(--noir);
  border-right: 2px solid var(--noir);
}
.select_elem select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
  color: var(--noir);
  width: 100%;
  padding: 15px;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.select_elem select:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}
