.loading {
  padding-bottom: 50px;
}

h2.heading {
  font-size: 18px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.mySwiper_block {
  margin-top: 10px;
}

.Hotel_recomande .prev,
.Hotel_recomande .next {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 255);
  color: var(--noir);
  border: 1px solid #ccc;
  top: 30%;
  z-index: 20;
  cursor: pointer;
  transition: 0.1s ease-in-out;
  visibility: visible;
}

.Hotel_recomande .prev:hover,
.Hotel_recomande .next:hover {
  background: #fff;
  transform: scale(1.1);
}

.Hotel_recomande .prev {
  left: 10px;
}

.Hotel_recomande .next {
  right: 10px;
}

.Hotel_recomande .prev.disable,
.Hotel_recomande .next.disable {
  visibility: hidden;
}

.box_image {
  margin: 10px 0 40px;
  overflow: hidden;
  position: relative;
}

.box_image a {
  text-decoration: none;
}

.box_image .image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 180px;
  position: relative;
  border-radius: 15px;
  overflow: hidden;
  background: #ddd;
}

.box_image .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.recomande_desc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}

.recomande_desc p {
  font-size: 12px;
}

.annonce_head {
  display: flex;
  justify-content: space-between;
}

.hotel_evaluation {
  display: flex;
}

.hotel_evaluation .block_eval {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--noir6);
}

.hotel_evaluation p {
  font-size: 14px;
}

.status_block svg {
  height: 10px;
  width: 10px;
}

.status_block svg.encours {
  fill: #ccc;
}

.status_block svg.enregistrer {
  fill: #ffaf0f;
}

.status_block svg.masquer {
  fill: #c13515;
}

.status_block svg.publier {
  fill: #008a05;
}

.status_block svg.desactiver {
  display: block;
  height: 14px;
  width: 14px;
  fill: rgb(193, 53, 21);
}

.descrip_detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 5px;
}

.descrip_detail p {
  color: var(--noir4);
  font-size: 15px;
}

.recomande_desc h5 {
  font-size: 16px;
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media screen and (max-width: 768px) {
  h2.heading {
    line-height: 28px;
  }
}

@media screen and (max-width: 743px) {
  .Hotel_recomande .prev,
  .Hotel_recomande .next {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .mySwiper_block {
    margin: 10px -24px 0;
    padding: 0 24px;
  }

  .box_image .image {
    height: 200px;
  }
}

@media screen and (max-width: 414px) {
  .box_image .image {
    height: 150px;
  }
}
