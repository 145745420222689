.block_content {
  padding: 24px 30px;
}

.block_head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 10px;
}

.block_head p {
  font-size: 23px;
  color: var(--noir);
}

.block_head a {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.block_content > p {
  padding-bottom: 10px;
  font-size: 23px;
  color: var(--noir);
}

.bouton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.bouton a {
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 20px;
  text-align: center;
  background: rgb(214, 214, 214);
  color: rgb(83, 83, 83);
  font-size: 14px;
  transition: 0.1s ease-in;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}
.bouton a:hover {
  background: rgb(223, 223, 223);
}
.bouton a.active {
  background: rgb(243, 243, 243);
  cursor: no-drop;
}
@media (max-width: 1100px) {
  .bouton a {
    padding: 10px 30px;
  }
}
@media (max-width: 820px) {
  .bouton {
    flex-direction: column;
  }
  .bouton a {
    width: 100%;
  }
}
/*-----------bar de recherche------------*/
.container_bouton .search {
  padding: 30px 0 10px;
  display: flex;
  justify-content: flex-end;
}
.search_input {
  position: relative;
}
.search_input input {
  width: 100%;
  height: 45px;
  padding: 5px 10px 5px 30px;
  outline: none;
  border: rgb(197, 197, 197) 1px solid;
  border-radius: 5px;
  color: var(--noir);
  transition: 0.1s ease-in;
}
.search_input input:focus {
  border: 1px solid #d036ff33;
  box-shadow: 0 0px 1px 3px #cd2bff33;
}
.search i {
  position: absolute;
  left: 0;
  top: 15px;
  padding-left: 5px;
  font-size: 15px;
  color: rgb(116, 116, 116);
}
@media screen and (max-width: 820px) {
  .container_bouton .search {
    width: 100%;
    justify-content: center;
  }
}
/*-----------table------------*/
.total_nbr {
  background: #2187fc;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 10px;
  display: inline-block;
}
.liste {
  padding: 0px 0px 50px;
}
.customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}
.customers tr:hover {
  background-color: #f2f2f2;
}
.customers th {
  background-color: #fff;
  color: var(--noir);
  font-size: 14px;
}
.customers td,
.customers th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  font-size: 14px;
}
.customers td {
  color: var(--noir);
}
.customers td .telephone {
  text-decoration: none;
  color: rgb(0, 100, 214);
}
.customers td .telephone:hover {
  text-decoration: underline;
  color: rgb(0, 100, 214);
}
.connect {
  display: flex;
  justify-content: center;
  align-items: center;
}
.connect span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #008a05;
}
.valid,
.isDisabled {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
}
.valid svg,
.isDisabled svg {
  display: block;
  height: 16px;
  width: 16px;
}
.valid svg.no,
.isDisabled svg.no {
  fill: var(--border-color);
}
.valid svg.yes {
  fill: #008a05;
}
.valid span,
.isDisabled span {
  font-size: 14px;
  color: var(--noir);
}
.isDisabled svg.yes {
  fill: #c13515;
}
.action {
  display: flex;
  justify-content: center;
  gap: 8px;
  align-items: center;
}
.action a {
  text-decoration: none;
  color: var(--noir);
}
.action button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
p.aucun-resultat {
  font-size: 18px;
  padding-top: 10px;
}
/*------- table pour les utilisateurs--------*/
.customers td.image_user_table {
  border: none;
  padding: 0px;
}
.image_user_table {
  display: flex;
  justify-content: center;
  align-items: center;
}
.image_user_table a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: none;
  overflow: hidden;
}
.image_user_table svg {
  display: block;
  height: 100%;
  width: 100%;
  fill: #717171;
  background: #fff;
  stroke-width: 3;
}
.image_user_table img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
