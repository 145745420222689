.modal_content {
  position: absolute;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  width: 580px;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;
  animation-duration: 400ms;
  animation-iteration-count: 1;
  animation-fill-mode: both;
}

.modal_content {
  animation-name: modal_content_slideOutn;
}

.modal_content.active {
  animation-name: modal_content_slideIn;
}

@keyframes modal_content_slideIn {
  from {
    opacity: 0;
    transform: translateX(100%);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@keyframes modal_content_slideOutn {
  from {
    opacity: 1;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translateX(100%);
  }
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  overflow-y: auto;
  padding: 10px 24px 50px;
}

.block_section_filter {
  border-bottom: 1px solid var(--border-color);
  padding: 30px 0;
}

.m_body .block_section_filter h3 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: #000;
  padding-bottom: 20px;
}

/*------checkbox-type logement----*/
.block_section_filter p {
  font-size: 13px;
}

.block_section_filter button {
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  transition: 0.3s;
}

.block_section_filter button:active {
  transform: scale(0.9);
}

.grid_system {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.checkox_contener {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.checkox_contener label {
  width: 100%;
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
}

/*------checkbox-chambre et lits----*/
.chambre_lit {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.chambre_lit p {
  font-size: 14px;
  padding-bottom: 15px;
}

.radio_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.radio_container label {
  cursor: pointer;
}

.radio_container label input[type="radio"] {
  display: none;
}

.radio_container label span {
  position: relative;
  display: inline-block;
  border-radius: 50px;
  padding: 5px 12px;
  border: 1px solid #ddd;
  background: rgb(247, 247, 247);
  font-weight: var(--font_display_1_font-weight);
  font-size: 15px;
  color: var(--noir);
  user-select: none;
  overflow: hidden;
  transition: 0.2s;
}

.radio_container label span:hover {
  background: rgb(235, 235, 235);
}

.radio_container label input[type="radio"]:checked ~ span {
  background: var(--btn_noir1);
  border: 1px solid var(--btn_noir1);
  color: #fff;
}

.equipement {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.equipement p {
  font-size: 14px;
  font-weight: var(--font_display_2_font-weight);
  padding-bottom: 15px;
}

/*------block button----*/
.m_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 16px 24px;
  background: #fff;
  border-top: 1px solid var(--border-color);
}

.m_footer .effacher {
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  transition: 0.3s;
}

.m_footer .effacher:active {
  transform: scale(0.9);
}

.m_footer .appliquer {
  min-width: 180px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  color: #fff;
  border: none;
  outline: none;
  background: var(--btn_noir1);
  border-radius: 8px;
  transition: 0.3s;
}

.m_footer .appliquer:hover {
  cursor: pointer;
  background: var(--btn_noir2);
}

.m_footer .appliquer:active {
  transform: scale(0.9);
}

.m_footer .affiMobile {
  display: none;
}

@media screen and (max-width: 743px) {
  .modal_content {
    border-radius: 0;
    width: auto;
    top: 12px;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 10px 10px 0 0;
  }

  @keyframes modal_content_slideIn {
    from {
      opacity: 0;
      transform: translateY(100%);
    }

    to {
      opacity: 1;
      transform: none;
    }
  }

  @keyframes modal_content_slideOutn {
    from {
      opacity: 1;
      transform: none;
    }

    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }

  .m_head {
    min-height: 48px;
  }

  .radio_container {
    margin: 0 -24px;
    padding: 0 24px;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: auto;
  }

  .radio_container::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 540px) {
  .m_body::-webkit-scrollbar {
    display: none;
  }

  .block_section_filter {
    padding: 24px 0;
  }
}

@media screen and (max-width: 500px) {
  .grid_system {
    grid-template-columns: 1fr;
  }
}

.m_footer .appliquer {
  font-size: 15px;
}
