/*------avis-----*/
.section {
  padding-bottom: 30px;
}

@media screen and (max-width: 700px) {
  .section {
    padding-bottom: 20px;
  }
}

.section h3 {
  font-size: 22px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
  padding: 24px 0;
  line-height: 1.2;
}

.total_note {
  display: flex;
  align-items: center;
  gap: 5px;
}

.avis_section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 10px;
}

.avis_section .bar_avis {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 10px 0;
}

.avis_section .bar_avis p {
  font-size: 16px;
}

.bar_avis .bar_flex {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
}

.bar_avis .bar_flex .chiffre {
  display: inline-block;
  font-size: 12px;
  margin-left: 8px;
}

.bar_avis .pourcentage {
  position: relative;
  width: 150px;
  height: 4px;
  background: #ddd;
  border-radius: 4px;
}

.bar_avis .pourcentage span {
  position: absolute;
  height: 100%;
  background: var(--noir);
  border-radius: 4px;
}

@media screen and (max-width: 770px) {
  .avis_section {
    grid-template-columns: 1fr;
  }

  .avis_section .bar_avis {
    padding: 5px 0;
  }

  .bar_avis .bar_flex {
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 375px) {
  .bar_avis .pourcentage {
    width: 120px;
  }
}

@media screen and (max-width: 280px) {
  .bar_avis .pourcentage {
    width: 70px;
  }
}
