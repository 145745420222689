.modal_content {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
}

.m_body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  padding: 10px 24px 20px;
  overflow-y: auto;
}

.m_body_head {
  margin-bottom: 10px;
}

.m_body_head h2 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
}

.m_body_head p {
  font-size: 15px;
  color: var(--noir6);
  margin-top: 10px;
}

.m_body_head p strong {
  font-size: 20px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body label {
  display: inline-block;
  font-size: 15px;
  margin-bottom: 5px;
}

.m_body input,
.m_body select {
  box-sizing: border-box;
  width: 100%;
  padding: 0 15px;
  margin: 0;
  height: 50px;
  font-size: 15px;
  border-radius: 8px;
  border: 1px solid var(--noir9);
  color: var(--noir);
  background-clip: padding-box;
  background: transparent;
}

.m_body textarea {
  width: 100%;
  height: 170px;
  resize: vertical;
  border: 1px solid var(--noir9);
  border-radius: 8px;
  padding: 0 15px;
  font-size: 16px;
  max-height: 50vh;
  min-height: 144px;
  text-overflow: ellipsis;
  line-height: 28px;
}
.m_body p.error {
  font-size: 13px;
  padding-top: 5px;
  color: rgb(193, 53, 21);
}
.btn_action {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  border-top: 1px solid var(--border-color);
}
.btn_action button {
  outline: none;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  height: 48px;
  border-radius: 8px;
  transition: 0.3s;
}
.btn_action button:nth-child(1) {
  background: transparent;
  color: var(--noir);
  text-decoration: underline;
  padding: 0 10px;
  margin-left: -10px;
}
.btn_action button:nth-child(1):hover {
  background-color: var(--gray-hover);
}
.btn_action button:nth-child(2) {
  background: var(--btn_noir1);
  color: #fff;
  padding: 0 24px;
}
.btn_action button:nth-child(2):hover {
  background: var(--btn_noir2);
}
.btn_action button:active {
  transform: scale(0.9);
}
.btn_action button.loading {
  border: none;
  outline: none;
  background: rgb(226, 226, 226);
  padding: 0 25px;
  width: 110px;
  height: 48px;
  border-radius: 8px;
  color: #fff;
  cursor: no-drop;
  transition: 0.3s;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
