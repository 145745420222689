.modal_content {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  border-bottom: 1px solid var(--border-color);
}

.m_head h3 {
  font-size: 15px;
  color: var(--noir);
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  overflow-y: auto;
  padding: 10px 24px 50px;
}

.block_section_filter {
  border-bottom: 1px solid var(--border-color);
  padding: 30px 0;
}

.m_body .block_section_filter h3 {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: #000;
  padding-bottom: 20px;
}

/*------checkbox-type logement----*/
.block_section_filter p {
  font-size: 13px;
}

.block_section_filter button {
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  color: var(--noir);
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  transition: 0.3s;
}

.block_section_filter button:active {
  transform: scale(0.9);
}

.grid_system {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.checkbox_container {
  display: flex;
  align-items: center;
  padding: 5px 0;
}

.checkbox_container label {
  width: 100%;
  font-size: 14px;
  padding-left: 10px;
  cursor: pointer;
}

/*------block button----*/
.m_footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  padding: 16px 24px;
  background: #fff;
  border-top: 1px solid var(--border-color);
}

.m_footer .effacher {
  border: none;
  outline: none;
  background: transparent;
  text-decoration: underline;
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  cursor: pointer;
  transition: 0.3s;
}

.m_footer .effacher:active {
  transform: scale(0.9);
}

.m_footer .appliquer {
  min-width: 180px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  color: #fff;
  border: none;
  outline: none;
  background: var(--btn_noir1);
  border-radius: 8px;
  transition: 0.3s;
}

.m_footer .appliquer:hover {
  cursor: pointer;
  background: var(--btn_noir2);
}

.m_footer .appliquer:active {
  transform: scale(0.9);
}

.m_footer .affiMobile {
  display: none;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
@media screen and (max-width: 540px) {
  .m_body::-webkit-scrollbar {
    display: none;
  }

  .block_section_filter {
    padding: 24px 0;
  }
}

@media screen and (max-width: 500px) {
  .grid_system {
    grid-template-columns: 1fr;
  }
}

.m_footer .appliquer {
  font-size: 15px;
}
