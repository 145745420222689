.container {
  max-width: 800px;
  margin-top: 24px;
}

.container h1 {
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding-bottom: 10px;
}

.main {
  padding: 0 100px 100px;
}
.net p,
.detail p {
  color: var(--noir8);
}
.net {
  margin: 40px 0 50px;
}

.net h2 {
  font-size: 25px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
}
.detail {
  display: flex;
  gap: 50px;
}
.detail h2 {
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir4);
  margin-bottom: 10px;
}
.detail_block {
  display: flex;
  align-items: center;
  gap: 10px;
}
.detail_block span {
  display: block;
  width: 16px;
  height: 16px;
}
.detail_block span.black {
  background-color: var(--noir);
}
.detail_block span.gray {
  background-color: #b0b0b0;
}
.infos h3 {
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  margin: 50px 0 24px;
}
.select_elem {
  position: relative;
  width: 100%;
}
.select_elem::after {
  position: absolute;
  content: "";
  width: 12px;
  height: 12px;
  top: 50%;
  right: 40px;
  transform: rotate(45deg);
  border-bottom: 2px solid var(--noir);
  border-right: 2px solid var(--noir);
}
.select_elem select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 20px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
  width: 100%;
  margin: 25px 24px 5px;
  padding: 15px;
  margin-left: -15px;
  border: none;
  outline: none;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.select_elem select:focus {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.infos .infos_block {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 24px 0;
  border-top: 1px solid var(--border-color);
}
.infos_block_number {
  color: var(--noir8);
}
@media screen and (max-width: 950px) {
  .main {
    padding: 0 24px 100px;
  }
  .net h2 {
    font-size: 35px;
  }
  .detail h2 {
    font-size: 18px;
    font-weight: var(--font_display_2_font-weight);
  }
}

@media screen and (max-width: 743px) {
  .net h2 {
    font-size: 25px;
  }
  .detail h2 {
    font-size: 16px;
  }
}
