.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  background: #fff;
  height: 100vh;
  padding: 24px;
}
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.block {
  display: flex;
  align-items: center;
  gap: 10px;
}
.block p {
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: rgb(193, 53, 21);
}
.block svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: rgb(193, 53, 21);
}
.main a {
  display: inline-block;
  color: var(--noir);
  font-size: 15px;
}
.actions a {
  display: inline-block;
  border: none;
  color: #fff;
  padding: 15px 24px;
  background: var(--btn_noir1);
  text-decoration: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}
.actions a:hover {
  background: var(--btn_noir2);
}
.actions a:active {
  transform: scale(0.9);
}
