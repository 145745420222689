/*---componete-photo-----*/
.block {
  padding-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--border-color);
}

.head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.head h3 {
  color: var(--noir);
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.head a {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--noir);
  padding: 6px 10px;
  margin-top: -6px;
  margin-right: -10px;
  border-radius: 5px;
}

.head a span {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
}

.head a:hover {
  background-color: var(--gray);
}

.head a:active {
  transition: 0.3s;
  transform: scale(0.9);
}

.main p {
  color: #717171;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow-y: hidden;
}

.main p strong {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

/*-----pages equipements--------*/

.dispositif h3 {
  padding-top: 30px;
  font-size: 22px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.container_equip h4 {
  font-size: 18px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--noir);
  padding-top: 50px;
}

.checkbox_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}

.checkbox_container:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.checkbox_container label {
  width: 100%;
  font-size: 16px;
  color: var(--noir4);
  cursor: pointer;
}

@media screen and (max-width: 743px) {
}
