.container {
  padding-top: 24px;
}

.container a {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: transparent;
  border: none;
  padding: 5px 15px;
  margin-left: -14px;
  border-radius: 8px;
  transition: 0.3s;
  text-decoration: none;
  color: var(--noir);
}

.container a:hover {
  background-color: var(--gray);
}

.container a:active {
  transform: scale(0.9);
}

.container svg {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: currentcolor;
  stroke-width: 4;
  overflow: visible;
}

.container p {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}
