.filter_block {
  display: flex;
  align-items: center;
  gap: 10px;
  color: var(--border-color);
}

.filter_block button {
  position: relative;
  background: transparent;
  border: none;
  display: flex;
  color: var(--noir);
  box-shadow: 0 0 0 1px var(--border-color) inset;
  transition: transform 0.3s;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.filter_block button:hover {
  background-color: var(--gray);
}

.filter_block button:active {
  transform: scale(0.9);
}

.filter_block button.active {
  box-shadow: var(--box-shadow-border) inset;
}

.filter_block button span.length {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: -2px;
  right: -2px;
  width: 18px;
  height: 18px;
  font-size: 10px;
  font-weight: var(--font_display_3_font-weight);
  border-radius: 50%;
  background: #000;
  color: var(--white);
  z-index: 10;
}

.filter_block button.active span.length {
  display: flex;
}

@media screen and (max-width: 743px) {
  .filter_block {
    justify-content: end;
  }
}
