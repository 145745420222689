.container input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(176, 176, 176);
  background: rgb(255, 255, 255);
  overflow: hidden;
  border-radius: 50%;
  vertical-align: top;
  outline: none;
  cursor: pointer;
  box-sizing: border-box;
  height: 22px;
  width: 22px;
  margin: 0px;
}

.container input[type="radio"]:hover {
  border-color: rgb(34, 34, 34);
}

.container input[type="radio"]:checked {
  border-color: rgb(34, 34, 34);
  border-width: 7px;
  background: white;
}
