/**-------sweetalert--------*/
.actions_class {
  display: flex !important;
  padding: 0 30px !important;
  justify-content: space-between !important;
}
.cancel_button_class {
  color: #222222 !important;
  background-color: transparent !important;
  text-decoration: underline;
  margin-left: -15px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
}
.confirm_button_class {
  border: none;
  outline: none !important;
  color: #fff;
  background-color: var(--btn_noir1) !important;
  height: 45px;
  padding: 0 25px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px !important;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: none !important;
}
.confirm_button_class:hover {
  background-color: var(--btn_noir2);
}
