.setings_block {
  display: block;
}

.setings_block a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  color: var(--noir);
  gap: 12px;
  height: auto;
  padding: 20px;
  margin: 0 -20px;
}

.setings_block .left_block {
  display: flex;
  gap: 12px;
}

.setings_block a:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}

.setings_block a:hover {
  background-color: var(--gray);
}

.setings_block a.active {
  background-color: var(--gray);
}

.setings_block a h2 {
  color: var(--noir);
  line-height: 30px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.setings_block a p {
  color: var(--noir8);
  font-size: 14px;
}

@media screen and (max-width: 716px) {
  .setings_block a {
    padding: 20px 0;
    margin: 0;
  }

  .setings_block a:hover {
    background-color: transparent;
  }
}
