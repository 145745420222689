/*-----tableau racourcie--------*/
.container {
  padding-top: 50px;
  display: flex;
  align-items: flex-end;
  gap: 24px;
  margin-bottom: 50px;
}

.tab_block {
  flex: 50%;
  height: 100%;
}

.tab_block_2 {
  flex: 50%;
}

.tab_colum {
  display: flex;
  justify-content: space-between;
}

.tab_text p {
  color: var(--noir);
  font-weight: 100;
}

.tab_text p.mini {
  font-size: 12px;
  color: var(--noir);
}

.tab_icon {
  font-size: 30px;
}

.tab_icon i {
  padding: 10px;
  text-align: center;
  background-color: var(--gray);
  border-radius: 50%;
}

.colum_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  margin-top: 10px;
}

.colum_2 .colum_2_text {
  height: 140px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 10px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0 0px 5px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--gray);
  transition: 0.3s ease-in;
}

.colum_2 .colum_2_text:hover {
  transform: translatey(-5px);
}

.colum_2_text > p {
  padding: 5px;
  font-size: 18px;
  font-weight: 100;
}

.colum_2_text .number {
  padding: 0;
  font-size: 16px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

@media screen and (max-width: 770px) {
  .container {
    display: block;
  }

  .tab_block {
    margin-top: 24px;
  }

  .colum_2 .colum_2_text {
    height: auto;
  }
}
