.container input {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 23px;
  height: 23px;
  padding: 0;
  margin: 0px;
  cursor: pointer;
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #c6c6c6;
}

.container input:hover {
  border: 1px solid var(--btn_noir1);
}

.container input:after {
  content: "";
  display: none;
  position: absolute;
  top: 2px;
  left: 8px;
  width: 5px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
}

.container input:checked {
  background: var(--btn_noir1);
  border: 1px solid var(--btn_noir1);
}

.container input:checked:after {
  display: block;
}
