
.container label {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 15px;
  border-radius: 8px;
  color: var(--noirc);
  background: #f5f5f5;
  box-shadow: rgb(176 176 176) 0px 0px 0px 1px inset;
  overflow: hidden;
  cursor: pointer;
}

.container label:focus-within {
  box-shadow: rgb(34 34 34) 0px 0px 0px 2px inset;
}

.container input {
  box-sizing: border-box;
  padding-left: 5px;
  width: 100%;
  height: 40px;
  font-size: 15px;
  color: var(--noir);
  background-clip: padding-box;
  background: transparent;
  white-space: nowrap;
  border: none;
  outline: none;
}

.close_disable {
  opacity: 0;
}

.close_active {
  opacity: 1;
}

.container label button.close {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 20px;
  height: 20px;
  background: rgb(221, 221, 221);
  border-radius: 50%;
  border: none;
  outline: none;
  color: rgb(34, 34, 34);
  cursor: pointer;
  touch-action: manipulation;
  transition: -ms-transform 0.25s ease 0s, -webkit-transform 0.25s ease 0s, transform 0.25s ease 0s;
}

.container button svg {
  display: block;
  fill: none;
  height: 12px;
  width: 12px;
  stroke: currentcolor;
  stroke-width: 5.33333;
  overflow: visible;
}
