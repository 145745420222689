.c_head .descr {
  display: flex;
  justify-content: space-between;
}

.c_head h4 {
  color: var(--noir);
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
}

.c_head p {
  color: #717171;
  font-size: 13px;
}
