svg.error {
  display: block;
  height: 14px;
  width: 14px;
  fill: #c13515;
}

svg.google_icon {
  display: block;
  height: 20px;
  width: 20px;
}

.block_svg {
  background-color: #c13515;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.block_svg svg {
  display: block;
  height: 16px;
  width: 16px;
  fill: #fff;
}

svg.no {
  display: block;
  height: 16px;
  width: 16px;
  fill: var(--border-color);
}

svg.yes {
  display: block;
  height: 16px;
  width: 16px;
  fill: #008a05;
}

svg.pending,
svg.check {
  height: 12px;
  width: 12px;
  fill: currentColor;
}

.right svg {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: currentColor;
  stroke-width: 4;
  overflow: visible;
}

.close_svg {
  display: block;
  fill: none;
  height: 16px;
  width: 16px;
  stroke: currentcolor;
  stroke-width: 3;
  overflow: visible;
  z-index: 3;
}

.stripe {
  height: 30px;
}
