.container {
  display: flex;
  flex-direction: column;
  gap: 45px;
  margin-top: 24px;
  max-width: 700px;
}

.container_block {
  padding-left: 24px;
  padding-bottom: 24px;
  border-left: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.p_date {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir8);
}

.container_block h2 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
  margin: 5px 0;
}

.p_profil {
  font-size: 15px;
  margin-bottom: 3px;
}

.modified_block p {
  font-size: 15px;
  margin-bottom: 3px;
}

.modified_block p span {
  font-size: 14px;
  color: var(--noir8);
}

.footer {
  margin-top: 20px;
}

.footer a {
  font-size: 15px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

@media screen and (max-width: 743px) {
  .container_block {
    padding-left: 0;
    border-left: none;
  }
}
