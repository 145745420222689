/*-----footer----*/
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 16px;
  background: #fff;
  border-top: 1px solid rgb(221, 221, 221);
}

.footer button.retour {
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  padding: 10px;
  margin-left: -10px;
  color: var(--noir);
  text-decoration: underline;
  cursor: pointer;
}

.footer button.retour:hover {
  background: var(--gray);
}

.footer button.next {
  position: relative;
  border: none;
  outline: none;
  color: #fff;
  background: var(--btn_noir1);
  width: 130px;
  height: 40px;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}

.footer button.next:hover {
  background: var(--btn_noir2);
}

.footer button.next:active {
  transform: scale(0.9);
}

.footer button.next.disable {
  background: rgb(226, 226, 226);
  color: #fff;
  cursor: no-drop;
}

.footer button.btn_loading {
  width: 130px;
  height: 40px;
  border: none;
  outline: none;
  background: rgb(226, 226, 226);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
}
