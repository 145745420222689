 .close_btn {
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
   position: relative;
   color: var(--noir);
   background-color: transparent;
   outline: none;
   border: none;
 }

 .close_btn::before {
   content: '';
   display: block;
   position: absolute;
   width: 32px;
   height: 32px;
   border-radius: 50px;
   background-color: transparent;
   z-index: 1;
 }

 .close_btn:hover::before {
   background-color: var(--gray);
 }