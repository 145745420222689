.container {
  padding: 30px 85px 60px;
  height: calc(var(--view-height) - var(--navbar-height));
  overflow-y: auto;
}

.container_load {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (min-width: 1440px) {
  .container {
    max-width: 1380px;
    margin: 0 auto;
  }
}

.navigation {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  margin: 50px 0 50px;
}

.navigation button {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
  border: 1px solid var(--border-color);
  outline: none;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px -1px rgba(0, 0, 0, 0.1);
  background: transparent;
  user-select: none;
  overflow: hidden;
}

.navigation button p {
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
}

.navigation button:active {
  transition: 0.3s transform;
  transform: scale(0.9);
}

.navigation button.active {
  background: var(--gray);
  box-shadow: var(--box-shadow-border);
}

@media screen and (max-width: 1092px) {
  .navigation {
    display: flex;
    gap: 10px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    margin: 0 -50px;
    padding: 50px 50px;
  }

  .navigation::-webkit-scrollbar {
    display: none;
  }

  .navigation button {
    min-width: 300px;
    scroll-snap-align: center;
  }
}

@media screen and (max-width: 900px) {
  .container {
    padding: 30px 24px 60px;
  }
  .navigation {
    margin: 0 -24px;
    padding: 30px 24px;
  }
}

@media screen and (max-width: 743px) {
  .navigation button:active {
    transform: scale(1);
  }
}
