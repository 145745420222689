.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: var(--sidebar-with);
  height: 100vh;
  padding: 20px 12px 20px;
  z-index: 999;
  background: #fff;
  box-shadow: rgb(0 0 0 / 15%) 1px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* .s_head */
.s_head {
  min-height: 70px;
}

.logo_block a {
  text-decoration: none;
}

.logo_block .icon {
  display: none;
  justify-content: center;
}

/* .s_body */
.s_body {
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: flex-start;
  height: 100%;
  overflow-y: auto;
}

.s_body::-webkit-scrollbar {
  display: none;
}

.s_body a,
.s_body button,
.s_footer button,
.s_footer a {
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  text-decoration: none;
  width: 100%;
  background: transparent;
  border: none;
  border-radius: 8px;
  padding: 12px;
  color: var(--noir);
  cursor: pointer;
}

.s_body .icon_content {
  position: relative;
  display: flex;
  align-items: center;
}

.s_body .icon_content .icon_notif {
  display: block;
  position: absolute;
  top: -3px;
  right: -3px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: rgb(255, 56, 92);
  animation-duration: 0.3s;
  animation-name: notification_anim;
}

.s_body .dot_message {
  position: absolute;
  top: -7px;
  right: -7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: var(--font_display_2_font-weight);
  height: 18px;
  min-width: 18px;
  max-width: 36px;
  border-radius: 50px;
  border: 1px solid #fff;
  background: rgb(255, 56, 92);
  animation-duration: 0.3s;
  animation-name: notification_anim;
}

.s_body .dot_message .dot_content {
  padding: 3px 5px;
  font-size: 0.6875rem;
  color: #fff;
}

.icon_content svg {
  display: block;
  height: 24px;
  width: 24px;
  stroke: currentColor;
}

.icon_content svg,
.s_footer .content_img {
  transition: transform 0.2s;
}

.icon_content svg.message,
.icon_content svg.reservation,
.icon_content svg.notification {
  stroke-width: 0.2;
}

.icon_content svg.logout_svg {
  stroke-width: 1;
}

.icon_content svg.profil,
.icon_content svg.statistic_svg {
  stroke-width: 0.5;
  fill: var(--noir);
}

.icon_content svg.add_home,
.icon_content svg.host_mode,
.icon_content svg.account {
  stroke-width: 2;
}

.icon_content svg.search,
.icon_content svg.favori,
.icon_content svg.host_mode,
.icon_content svg.account {
  fill: none;
}

.s_body a p,
.s_body button p {
  font-weight: 400;
  font-size: 14px;
  color: currentColor;
}

.s_body a:hover,
.s_body button:hover {
  background: #f5f5f5;
}

.s_body a:hover .icon_content svg,
.s_body button:hover .icon_content svg {
  transform: scale(1.1);
}

.s_body a:active,
.s_body button:active {
  color: #888;
  background: #f8f8f8;
}

.s_body a.active {
  background: #f5f5f5;
}

.s_body a.active .icon_content svg,
.s_body button.active .icon_content svg {
  transform: scale(1.1);
}

.s_body a.active p {
  font-weight: var(--font_display_3_font-weight);
}

/* tooltip */
.tooltip {
  position: fixed;
  left: 70px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.28) 1px 2px 7px;
  border-radius: 5px;
  padding: 5px 10px;
  z-index: 2000;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  transition: all 0.1s ease-in-out;
}

.tooltip span {
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

/* sidebar reduce */

.container.hidded {
  width: var(--sidebar-with-hidded);
}

.container.hidded .logo_block .logo {
  display: none;
}

.container.hidded .logo_block .icon {
  display: flex;
}

.container.hidded .s_body a p,
.container.hidded .s_body button p {
  display: none;
}

.container.hidded .s_body a:hover .tooltip,
.container.hidded .s_body button:hover .tooltip {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
  transition-delay: 0.5s;
}

.s_body a:not(:hover) .tooltip,
.s_body button:not(:hover) .tooltip {
  visibility: hidden;
}

@media screen and (max-width: 1263px) {
  .logo_block .logo {
    display: none;
  }

  .logo_block .icon {
    display: flex;
  }

  .s_body a p,
  .s_body button p {
    display: none;
  }

  .s_body a:hover .tooltip,
  .s_body button:hover .tooltip {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
    transition-delay: 1s;
  }
}

@media screen and (max-width: 743px) {
  .container {
    display: none;
  }
}

@keyframes notification_anim {
  from {
    transform: scale(0);
    opacity: 0;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes tooltip_anim {
  from {
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
  }

  from {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
  }
}
