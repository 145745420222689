.block p,
.status_reservation p {
  display: flex;
  gap: 5px;
  align-items: center;
  width: fit-content;
  padding: 6px 8px;
  border-radius: 5px;
}

.block p span {
  color: currentColor !important;
  font-size: 13px;
  font-weight: var(--font_display_2_font-weight);
}

.block p.incomplet {
  color: rgb(84 89 105);
  background-color: #ebeef1;
}

.block p.complet {
  color: #006908;
  background-color: #d7f7c2;
}

.block p.traitement {
  color: #690060;
  background-color: #ffd6fd;
}

.block p.try {
  color: #695900;
  background-color: #f7ebc2;
}

.block p.mal_passe {
  color: #690000;
  background-color: #ffddd7;
}

.status_reservation p.avenir {
  color: #545969;
  background-color: #ebeef1;
}

.status_reservation p.demande {
  color: #545969;
  background-color: #ebeef1;
}

.status_reservation p.encour {
  color: #c4a300;
  background-color: #fff6ca;
}

.status_reservation p.terminer {
  color: #006908;
  background-color: #d7f7c2;
}

.status_reservation p.annuler {
  color: #690000;
  background-color: #ffddd7;
}
