.modal_content {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-width: 100%;
  max-height: 87vh;
  overflow: hidden;
}

.m_head {
  min-height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 0 24px;
}

.m_head h2 {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
}

.m_body {
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 100%;
  padding: 10px 24px 20px;
  overflow-y: auto;
}

.m_body ol {
  margin-left: 17px;
}

.m_body ol li {
  margin-bottom: 5px;
}

@media screen and (max-width: 743px) {
  .modal_content {
    max-height: 98dvh;
  }

  .m_head {
    min-height: 48px;
  }
}
