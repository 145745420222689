/*------erreur 404----------*/
.erreur404 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.erreur_main {
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  padding: 70px;
}
.erreur_main h1 {
  color: rgb(0, 115, 247);
}
.erreur_main h2 {
  font-size: 16px;
  padding: 40px 0px;
}
.erreur_main a {
  text-decoration: none;
  padding: 10px 15px;
  color: #fff;
  font-weight: bolder;
  font-size: 10px;
  border-radius: 30px;
  transition: 0.2s ease-in;
  background: rgb(33, 135, 252);
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}
.erreur_main a:hover {
  background: rgb(0, 90, 194);
}
