.container {
  max-width: 400px;
  width: 100%;
}

.wrapper_block {
  padding: 30px 0;
  border-bottom: 1px solid var(--border-color);
}

.wrapper_block h2 {
  font-weight: var(--font_heading_2_font-weight);
  font-size: 25px;
  margin-bottom: 10px;
}

.wrapper_block p {
  font-size: 15px;
}

@media screen and (max-width: 743px) {
  .wrapper_block h2 {
    font-size: 20px;
  }
}
