.container_load_liste {
  margin-top: 20px;
}

.main {
  padding: 0 50px 100px;
}

.head_title {
  font-size: 18px;
  font-weight: var(--font_display_2_font-weight);
}

.no_result {
  margin-top: 10px;
}

@media screen and (max-width: 950px) {
  .main {
    padding: 0 24px 100px;
  }
}
