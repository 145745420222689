.block_content {
  padding: 24px 30px;
}

.block_content > p {
  padding-bottom: 10px;
  font-size: 23px;
  color: var(--noir);
}

/*----------les bouton-droite-----------*/
.bouton {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.bouton a {
  border-radius: 5px;
  text-decoration: none;
  padding: 10px 20px;
  text-align: center;
  background: rgb(214, 214, 214);
  color: rgb(83, 83, 83);
  font-size: 14px;
  transition: 0.1s ease-in;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
}

.bouton a:hover {
  background: rgb(223, 223, 223);
}

.bouton a.active {
  background: rgb(243, 243, 243);
  cursor: no-drop;
}

@media (max-width: 1100px) {
  .bouton a {
    padding: 10px 30px;
  }
}

@media (max-width: 820px) {
  .bouton {
    flex-direction: column;
  }

  .bouton a {
    width: 100%;
  }
}

/*-----page detail--------*/
.detail_blog {
  padding: 50px;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  gap: 20px;
  background: rgb(0, 144, 170);
}

.detail_img {
  flex: 2;
  position: relative;
}

.detail_img img {
  width: 128px;
  height: 128px;
  border-radius: 50%;
  object-fit: cover;
}

.detail_profil h2 {
  padding: 20px 0;
  color: #fff;
  text-transform: uppercase;
}

.detail_profil p {
  color: #ffff;
  font-size: 15px;
}

.detail_info {
  padding: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.detail_info h2 {
  text-align: center;
  padding: 20px;
  font-size: 15px;
  font-weight: var(--font_display_3_font-weight);
  color: var(--bluetext);
}

.detail_info p {
  font-size: 15px;
  color: #585656;
  margin-bottom: 10px;
}

.detail_info p.error_message {
  color: #db0000;
}

.detail_info_1 {
  padding: 20px;
  font-size: 15px;
  border: 1px solid #e9e9e9;
  flex: 50%;
}

.detail_info_1 ul li,
.detail_info_1_switch_left {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 0;
  list-style: none;
  font-weight: bold;
  color: #707070;
}

.detail_info_1_switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
}

.detail_info_1 ul li:not(:first-child) {
  border-top: 1px solid #e9e9e9;
}

.detail_info_1 ul li span {
  color: #707070;
  font-weight: var(--font_display_1_font-weight);
}

.content_block h3 {
  color: var(--noir);
  font-size: 17px;
  font-weight: var(--font_display_2_font-weight);
  margin-bottom: 5px;
}

.content_block p {
  color: #717171;
  font-size: 15px;
  margin-bottom: 5px;
}

.modif_link {
  margin: 30px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.modif_link a {
  border: 1px solid var(--noir);
  border-radius: 8px;
  background-color: #fff;
  padding: 10px 25px;
  text-decoration: none;
  font-size: 16px;
  font-weight: var(--font_display_2_font-weight);
  color: var(--noir);
}

.modif_link a:hover {
  background-color: var(--gray);
}

@media (max-width: 1030px) {
  .detail_info {
    padding: 30px;
    display: flex;
    gap: 20px;
    flex-direction: column;
  }
}

@media (max-width: 770px) {
  .detail_info {
    padding: 0px;
    flex-direction: column;
  }

  .detail_blog {
    padding: 30px 10px;
    flex-direction: column;
    align-items: center;
  }

  .detail_profil {
    text-align: center;
  }

  .detail_info_1 {
    padding: 10px;
  }
}
